<template>
  <div class="zone-card">
    <zone-card :title="'招采信息'"></zone-card>
    <div class="search">
      <el-input v-model="query.title" placeholder="请输入内容"></el-input>
      <img slot="append" src="@/assets/image/pc/search-icon.png" style="cursor: pointer" @click="noticeQuery(0)" alt="搜索">
    </div>
    <div class="purchase-classify">

      <div class="classify-item" @click="changeNotice('classifyList',index)"
           @mouseover="changeIndex(index,'classifyList')"
           @mouseout="changeIndexBack(index,'classifyList')"
           v-bind:style="{'background-image':'url('+require('../../assets/image/pc/'+getImg(item,index)+'.png')+')','background-repeat':'no-repeat','background-size':'cover','background-position': 'center'}"
           v-for="(item,index) in classifyList" :key="item.active">
      </div>
    </div>
    <div class="announcement">
      <div class="announcement-item" @click="noticeDetails(announcement)" v-for="announcement in notBuyerNoticeList" :key="announcement.id+'sc'">
        <span class=" show-one-line">
          <el-tag effect="plain" class="announcement-type">
            {{ getAnnouncementType(announcement.purchaseMode) }}
          </el-tag>
          <span class="announcement-title" :title="announcement.title">
                          {{ announcement.title }}
          </span>

        </span>
        <div class="announcement-details">
          <div class="announcement-company show-one-line">
            <span
                v-if="announcement.tendereeName != announcement.companyName && announcement.tendereeName !=undefined && announcement.tendereeName !=null &&announcement.tendereeName !='' ">委托方：</span>
            <span :title="announcement.tendereeName">{{
                (announcement.tendereeName != undefined && announcement.tendereeName != null && announcement.tendereeName != '') ? announcement.tendereeName : announcement.companyName
              }}</span>
          </div>
          <div class="announcement-time">
            {{ formatDate(announcement.releaseTime, 'YYYY/MM/DD HH:mm:ss') }}
          </div>
        </div>
      </div>
      <el-empty v-if="notBuyerNoticeList == null || notBuyerNoticeList == undefined || notBuyerNoticeList.length == 0" style="width: 100%"></el-empty>
      <div class="classify-button">
        <el-divider  content-position="center">
          <div class="classify-more" @click="noticeQuery(1)" @mouseover="changeIndex(0,'announcementMoreList')"
               @mouseout="changeIndexBack(0,'announcementMoreList')" v-for="item in announcementMoreList" :key="item+'afsa'">
            <img class="w-100" v-show="!item.show" :src="require('../../assets/image/pc/'+item.unchecked+'.png')"
                 alt="">
            <img class="w-100" v-show="item.show" :src="require('../../assets/image/pc/'+item.active+'.png')" alt="">
          </div>
        </el-divider>
      </div>
    </div>
  </div>
</template>

<script>
import ZoneCard from "./zone-card";
import moment from "moment";
import company from "../home/company";

export default {
  name: "purchase-info",
  components: {ZoneCard},
  props: ['company'],
  data() {
    return {
      query: {
        title: "",
        current: 0,
      },
      total: 0,
      currentPage: 0,
      pageSize: 10,
      notBuyerNotice: {},
      notBuyerNoticeList: [],
      announcementMoreList: [
        {
          unchecked: 'view-more',
          active: 'view-more-active',
          show: false
        }
      ],
      classifyList: [{
        unchecked: 'classify-item1',
        active: 'classify-item1-active',
        noticeType: "RECRUIT_BULLETIN",
        show: false
      }, {
        unchecked: 'classify-item2',
        active: 'classify-item2-active',
        noticeType: "YS_BULLETIN",
        show: false
      }, {
        unchecked: 'classify-item3',
        active: 'classify-item3-active',
        noticeType: "CHANGE_BULLETIN",
        show: false

      }, {
        unchecked: 'classify-item4',
        active: 'classify-item4-active',
        noticeType: "BDB_HINT_BULLETIN",
        show: false

      }, {
        unchecked: 'classify-item5',
        active: 'classify-item5-active',
        noticeType: "BDB_AWARD_BULLETIN",
        show: false

      }, {
        unchecked: 'classify-item6',
        active: 'classify-item6-active',
        noticeType: "BGG_EXTEND_BULLETIN",
        show: false
      }]
    }
  },
  methods: {
    noticeDetails(announcement) {
      let json = {name:'zonePurchaseDetails','query':{'contentId': announcement.contentId, 'type': announcement.type,'companyId':this.company.id,'id':this.company.companyId,'formType':announcement.formType,vc:announcement.vc}}
      this.$router.push(json)
    },
    noticeQuery(type) {
      let json = {
        name: 'zonePurchase',
        'query': {
          'companyId': this.company.id,
          'id': this.company.companyId,
          'activeClass': this.query.current,
          'searchName': this.query.title
        }
      }
      if (type == 0){

      }else {
        json.query.searchName ='';
      }
      this.$router.push(json)
    },
    changeNotice(entryName, index) {
      let that = this;
      that[entryName].some(item => {
        item.show = false;
      })
      that.query.current = index;
      that[entryName][index].show = true;
      that['notBuyerNoticeList']= [];
      that.getNoticeList(that.classifyList[index].noticeType, null, 'notBuyerNoticeList');
    },
    changeIndex(e, entryName) {
      this[entryName][e].show = !this[entryName][e].show;
    },
    getImg(item, index) {
      if ((!item.show) && this.query.current != index) {
        return item.unchecked;
      } else if (item.show || this.query.current == index) {
        return item.active;
      }
    },
    changeIndexBack(e, entryName) {
      this[entryName][e].show = !this[entryName][e].show;
    },
    selectNotice: function (modelId, entityName, beanName, index) {
      let that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        let type = that.classifyList[index].noticeType;
        let purchaseMode = data.data.result.children[index].purchaseMode;
        that.getNoticeList(type, purchaseMode, beanName);
      }).catch(function (error) {

      })
    },
    getNoticeList(type, purchaseMode, beanName) {
      let that = this;
      that.query.type = type;
      if (that.company.companyId == '2706' || that.company.companyId == '5246' || that.company.companyId == '5245') {
        //中小服务平台的显示所有的
        that.query.companyName = "";
      } else {
        that.query.companyName = that.company.companyId;
      }
      if ((that.company.companyId != "" && that.company.companyId != "0" && that.company.companyId != null) || that.queryType == 'all') {
        that.$http.post(that.base + "/bulletin/getRecruit.do", {
          page: {
            current: that.currentPage,
            size: that.pageSize
          },
          cmsBulletinDTO: that.query
        }).then(function (data) {
          that[beanName] = data.data.result.records;
          that.total = data.data.result.total;
        }).catch(function (error) {
        })
      }
    },
    formatDate: function (de, fmt) {
      return moment(de).format(fmt);
    },
    getAnnouncementType(purchaseMode) {
      let announcementType = "";
      switch (purchaseMode) {
        case "10":
          announcementType = "招标";
          break;
        case "210":
          announcementType = "招标";
          break;
        case "20":
          announcementType = "招标";
          break;
        case "186":
          announcementType = "议价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
      }
      return announcementType;
    },
  },
  created() {
    this.selectNotice("recruit_notice", "notBuyerNotice", "notBuyerNoticeList", 0);
  }
}
</script>

<style scoped lang="scss">

.search {
  height: 40px;
  gap: 10px;
  display: flex;
}

.purchase-classify {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;

  .classify-item {
    cursor: pointer;
    height: 80px;
  }
}


.announcement {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;

  .announcement-item {
    cursor: pointer;
    width: 49.5%;
    font-size: 1.4rem;
    border-radius: 5px;
    padding: 15px 20px 0px 20px;
    //border: 1px solid #3783fa;
    margin-bottom: 10px;
    box-shadow: -1px 1px 4px 1px #e5e5e5;

  }

  .announcement-title {
    font-weight: bold;
  }

  .announcement-type {
    color: #3783FA;
    padding: 0px 20px;
    border: 1px solid #3783fa;
    border-radius: 5px;
    margin-right: 10px;
  }

  .announcement-details {
    display: flex;
    color: #CBCBCB;
    line-height: 40px;

    .announcement-time {
      margin-left: auto;
    }
  }

  .classify-button {
    width: 90%;
    margin: 10px auto;
  }

  .classify-more {
    width: 180px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 0px;
    background: white;
  }

  .el-divider {
    background: #5387F7;
  }

  .el-divider__text {
    padding: 0 8 s0px;
  }
}


@media screen and (max-width: 576px) {
  .purchase-classify {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;

    .classify-item {
      width: 31.8% !important;
      height: 60px;
      margin-bottom: 10px;
    }
  }
  .search {
    img {
      width: 100px;
    }
  }

  .announcement {
    display: flex;
    flex-wrap: wrap;

    .announcement-item {
      width: 100%;
    }

    .classify-more {
      width: 150px;
      margin-top: 0px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .announcement {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;

    .announcement-item {
      width: 100%;
    }

    .classify-more {
      width: 150px;
      margin-top: 0px;
    }
  }

  .purchase-classify {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;

    .classify-item {
      flex: 1;
      background-size: contain !important;
      height: 50px;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .purchase-classify {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;

    .classify-item {
      flex: 1;
      background-size: cover !important;
      height: 60px;
    }
  }
  .announcement {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;

    .announcement-item {
      width: 100%;
    }

    .classify-more {
      width: 200px;
      margin-top: 0px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .classify-item {
    width: 100%;
    height: 80px;
  }


  .search {
    height: 40px;
    gap: 10px;
    display: flex;
  }

  .purchase-classify {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    .classify-item {
      flex: 1;
    }
  }


  .announcement {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;

    .announcement-item {
      cursor: pointer;
      width: 49.5%;
      border-radius: 5px;
      padding: 15px 20px 0px 20px;
      //border: 1px solid #3783fa;
      margin-bottom: 10px;
      box-shadow: -1px 1px 4px 1px #e5e5e5;

    }

    .announcement-title {
      font-weight: bold;
    }

    .announcement-type {
      color: #3783FA;
      padding: 0px 20px;
      border: 1px solid #3783fa;
      border-radius: 5px;
      margin-right: 10px;
    }

    .announcement-details {
      display: flex;
      color: #CBCBCB;
      line-height: 40px;

      .announcement-time {
        margin-left: auto;
      }
    }

    .classify-button {
      width: 90%;
    }

    .classify-more {
      width: 180px;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 0px;
      background: white;
    }

    .el-divider {
      background: #5387F7;
    }

    .el-divider__text {
      padding: 0 8 s0px;
    }
  }


}

@media (min-width: 1200px) {
  .purchase-classify {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;

    .classify-item {
      flex: 1;
      height: 80px;
      background-size: cover !important;
    }

  }

  .announcement {
    padding-bottom: 10px;
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 576px) {
  .search .el-input .el-input__inner {
    height: 35px !important;
    line-height: 35px !important;
  }

  .search {
    img {
      height: 35px !important;
      width: 100px;
    }
  }
}
</style>