<template>
    <div class="nav-company left">
      <div class="comp-main">
        <div class="title-text">
          <img src="../../assets/image/pc/icon_company.png" style="height: 100%; width: 100%">
        </div>
        <div class="title-flag">
          <span>实力企业 汇聚财富 商机无限</span>
        </div>
      </div>

      <div class="comp-detail">
        <div class="comp-col" v-for="(item) in buyerList" :key="item.id">
            <div class="w-100 h-100">
              <a v-for="(imgItem) in item.imagesUrl" v-bind:key="imgItem.id">
                <img :src="imgItem.url" class="w-100 h-100 col-image"/>
              </a>
            </div>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  name: "company",
  data(){return{
    buyerList:[],
  }
  },
  created() {
    this.selectPromotionList("buyer", "buyer", "buyerList");
  },
  methods:{
    /*查询推广列表*/
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        this.$http.get(this.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
          }
          that[entityListName] = data.data.result;
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.nav-company{
  width: 1160px;
  background: #FFFFFF;
  height: 294px;
  margin: 20px auto 0;
}
.comp-main{
  height: 42px;
  width: 100%;
}
.comp-detail{
  height: 252px;
}
.comp-col{
  width: 210px;
  height: 90px;
  border: 1px solid #E5E5E5;
  float: left;
  margin-left: 19px;
  margin-top: 20px;
}
.col-image{
  padding: 0 20px;
}
.title-text{
  float: left;
  width: 136px;
  height: 42px;
  background: #D4000A;
  border-radius: 0px 70px 70px 0px;
}
.title-flag{
  float: left;
  width: 301px;
  height: 24px;
  font-size: 1.6rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
  margin: 9px 0px 9px 21px;
}
@media (min-width: 576px ) and (max-width: 768px) {
  .nav-company{
    width: 100%;
    background: #FFFFFF;
    height: 136px;
    margin: 10px auto 0;
  }
  .comp-main{
    height: 20px;
    width: 100%;
  }
  .comp-detail{
    height: 116px;
  }
  .comp-col{
    width: 17%;
    height: 43px;
    border: 1px solid #E5E5E5;
    float: left;
    margin-left: 2.5%;
    margin-top: 10px;
  }
  .col-image{
    padding: 0 10px;
  }
  .title-text{
    float: left;
    width: 65px;
    height: 20px;
    background: #D4000A;
    border-radius: 0px 34px 34px 0px;
  }
  .title-flag{
    float: left;
    width: 144px;
    height: 12px;
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    margin: 8px 0px 0px 12px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .nav-company{
    width: 720px;
    background: #FFFFFF;
    height: 175px;
    margin: 12px auto 0;
  }
  .comp-main{
    height: 26px;
    width: 100%;
  }
  .comp-detail{
    height: 149px;
  }
  .comp-col{
    width: 130px;
    height: 56px;
    border: 1px solid #E5E5E5;
    float: left;
    margin-left: 12px;
    margin-top: 12px;
  }
  .col-image{
    padding: 0 10px;
  }
  .title-text{
    float: left;
    width: 84px;
    height: 26px;
    background: #D4000A;
    border-radius: 0px 43px 43px 0px;
  }
  .title-flag{
    float: left;
    width: 187px;
    height: 14px;
    font-size: 1.2rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    margin: 8px 0px 0px 14px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .nav-company{
    width: 960px;
    background: #FFFFFF;
    height: 226px;
    margin: 14px auto 0;
  }
  .comp-main{
    height: 32px;
    width: 100%;
  }
  .comp-detail{
    height: 194px;
  }
  .comp-col{
    width: 173px;
    height: 68px;
    border: 1px solid #E5E5E5;
    float: left;
    margin-left: 16px;
    margin-top: 15px;
  }
  .col-image{
    padding: 0 20px;
  }
  .title-text{
    float: left;
    width: 102px;
    height: 32px;
    background: #D4000A;
    border-radius: 0px 53px 53px 0px;
  }
  .title-flag{
    float: left;
    width: 226px;
    height: 18px;
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin: 10px 0px 0px 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .nav-company{
    width: 1160px;
    background: #FFFFFF;
    height: 255px;
    margin: 20px auto 0;
  }
  .comp-main{
    height: 37px;
    width: 100%;
  }
  .comp-detail{
    height: 218px;
  }
  .comp-col{
    width: 210px;
    height: 79px;
    border: 1px solid #E5E5E5;
    float: left;
    margin-left: 19px;
    margin-top: 18px;
  }
  .col-image{
    padding: 0 20px;
  }
  .title-text{
    float: left;
    width: 119px;
    height: 37px;
    background: #D4000A;
    border-radius: 0px 61px 61px 0px;
  }
  .title-flag{
    float: left;
    width: 263px;
    height: 21px;
    font-size: 1.6rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin: 8px 0px 8px 18px;
  }
}
/*手机端*/
@media screen and (max-width: 576px) {
  .nav-company{
    display: none;
  }
}
</style>
