<template>
  <div class="zone-bg">
    <div class="zone-content">
      <div class="zone-content-left">
        <div class="zone-company-info">
          <a :href="company.linkUrl" target="_blank" class="zone-company">{{ company.title }}</a>
          <div class="zone-link">
            <span v-if="company.companyId =='6802'"  style="font-size: 22px;color: white;display: flex;margin-bottom: 5px"> <img width="30px" src="../../assets/image/pc/tel.png" alt=""><span>&nbsp;&nbsp;0731-89767891</span></span>
            <span v-html="company.keyword"></span><br>
            <span v-html="company.description"></span>
          </div>
        </div>
        <div class="zone-link-list">
          <div class="zone-link-title">友情链接</div>
          <div class="zone-link-item" v-for="(item,index) in companyTypeList" :key="'safgga'+index">
            <el-select size="mini" @change="toLink" :key="companyLinkKey[item.modelId]"
                       v-model="companyLinkModel[item.modelId]" :placeholder="item.categoryTitle">
              <el-option
                  v-for="item1 in companyLinkList[item.modelId]"
                  :key="item1.categoryUrl+'ajhgfhgjbk'"
                  :label="item1.categoryTitle"
                  :value="item1.categoryUrl"
              >
              </el-option>
            </el-select>
          </div>

        </div>
      </div>
      <div class="zone-qr-code">
        <div class="zone-qr-code-img">
          <img v-if="company.companyId =='6802'" width="87" height="87" src="@/assets/image/pc/yxewm.jpg" alt="">
          <img v-else src="@/assets/image/pc/icon_code.png" alt="">
        </div>
        <div>
          扫描二维码关注我们
        </div>
      </div>
    </div>
    <div class="zone-footer">
      <div class="footer-company">湖南华菱电子商务有限公司(技术支持单位)</div>
      <div class="footer-link">技术支持电话：400-800-7255</div>
      <div class="footer-address">联系地址：{{ configForm.workAddress }}</div>
    </div>
  </div>
</template>

<script>
import company from "../home/company";

export default {
  name: "zone-footer-two",
  props: ["company"],
  data() {
    return {
      configForm: {},
      companyTypeList: [],
      companyLinkList: {},
      companyLinkModel: {},
      companyLinkKey: {},
    }
  },
  created() {
    this.selectCategory("zone-company-link", 'companyTypeList')
    this.getConfigs(203);
  },
  methods: {
    toLink(val) {
      window.open(val);
    },
    /*查询栏目及子栏目列表*/
    selectCategory: function (modelId, name, model) {
      let that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result.children;
        that.$set(that, name, data.data.result.children);
        //强制更新数据
        that.$forceUpdate();
        that[name].some(item => {
          that.selectLinkList(item.modelId);
        })
      })
    },
    /*查询栏目及子栏目列表*/
    selectLinkList: function (modelId) {
      let that = this;
      that.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        let tempArray = [];
        data.data.result.children.some(item => {
          let tempId = item.modelId;
          let modelLeft = tempId.substr(0, tempId.lastIndexOf("-") + 1);
          tempId = modelLeft + that.$route.query.id;
          if (tempId == item.modelId) {
            tempArray.push(item);
          }
        })
        that.$set(that.companyLinkList, data.data.result.modelId, tempArray);
        that.$forceUpdate();
      })
    },
    getConfigs: function (id) {
      var that = this;
      this.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        var data = res.data.result;
        if (data) {
          that.configForm = data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
  }
}
</script>

<style scoped lang="scss">
.zone-bg {
  background: #333333;
  width: 100%;

  .zone-content {
    margin: 0 auto;
    width: 1160px;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;

    .zone-content-left {
      display: flex;
      gap: 30px;
      width: 900px;
    }


    .zone-company {
      font-size: 2.4rem;
      color: #FFFFFF;
      font-weight: bold;
    }

    .zone-link {
      font-size: 1.4rem;
      color: #9B9B9B;
      margin-top: 20px;
      padding-right: 20px;
      line-height: 30px;
    }

    .zone-link-list {
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      font-size: 1.4rem;
      color: #9B9B9B;
      margin-left: auto;
      margin-bottom: 10px;
      flex-wrap: wrap;
      width: 380px;

      .zone-link-title {
        color: white;
        font-weight: bold;
        font-size: 2.4rem;
        width: 100%;
      }

      .zone-link-item {
        width: 48%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        color: #9B9B9B !important;
      }
    }
  }


  .zone-qr-code {
    color: #9B9B9B;
    margin-left: auto;
    font-size: 1.4rem;

    .zone-qr-code-img {
      display: flex;
      height: 80%;
      justify-content: center;
      align-items: center;
    }
  }

  .zone-footer {
    color: #9B9B9B;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #9B9B9B;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px 0px;
    font-size: 1.4rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .zone-content {
    width: 100% !important;
  }
  .zone-qr-code {
    margin-left: auto;
  }
  .zone-content-left {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    width: auto !important;
  }
  .zone-company-info {
    max-width: 450px;
  }
  .zone-link-list {
    width: 320px !important;
  }
}

@media (min-width: 620px) and (max-width: 992px) {
  .zone-content {
    width: 100% !important;
    align-items: center;
  }
  .zone-qr-code {
    margin-left: auto;
  }
  .zone-content-left {
    display: flex;
    gap: 30px;
    flex-wrap: wrap !important;
    width: 430px !important;
  }
  .zone-link-list {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 620px) {
  .zone-content {
    width: 100% !important;
  }
  .zone-qr-code {
    margin: 0 auto;
    margin-top: 20px;
  }
  .zone-content-left {
    display: flex;
    gap: 30px;
    flex-wrap: wrap !important;
    width: auto !important;
  }
  .zone-link-list {
    width: 100% !important;
    margin-left: 0px !important;
    flex-wrap: wrap;
    justify-content: start !important;
  }
}
</style>
