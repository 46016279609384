<template>
  <div class="title">
    <div class="title-icon">&nbsp;</div>
    <div class="title-span">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "zone-card",
  props: ["title"]
}
</script>

<style scoped>
.title {
  margin-bottom: 10px;
}

.title-icon {
  width: 10px;
  height: 20px;
  margin: 9px 0px;
  background: #5387F7;
  float: left;
  border-radius: 10px;
}

.title-span {
  font-size: 2.3rem;
  font-weight: bold;
  margin-left: 20px;
}

@media screen and (max-width: 620px) {
  .title-icon {
    width: 10px;
    height: 20px;
    margin: 9px 0px;
    background: #5387F7;
    float: left;
    border-radius: 10px;
  }

  .title-span {
    font-size: 2.4rem;
    font-weight: bold;
    margin-left: 20px;
  }
}

</style>